export const EXPERIMENT = {
    eure27714: 'EURE-27714',
    eure25548: 'EURE-25548',
    eure25554: 'EURE-25554',
    eure28555: 'EURE-28555',
    eure29774: 'EURE-29774',
    eure29876: 'EURE-29876',
    remt569: 'REMT-569',
    repm844: 'REPM-844',
    smr1929: 'SMR-1929',
    smr2326: 'SMR-2326',
    smr3411: 'SMR-3411',
} as const;
