import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { P3 } from '@nexus/lib-react/dist/core/Typography';
import { Container, StyledTextArea } from '@widgets/CompareAds/CompareAdsDialog/components/Message/Message.theme';
import type { JSX } from 'react';

interface Props {
    message: string;
    onMessageChange: (text: string) => void;
}

const MAX_MESSAGE_LENGTH = 2000;

export const Message = ({ message, onMessageChange }: Props): JSX.Element => {
    const [t] = useTranslations();

    return (
        <Container>
            <P3 variant="bold">{t('frontend.widget.compare-ads.message-heading')}</P3>
            <StyledTextArea
                value={message}
                onChange={({ target }) => onMessageChange(target.value)}
                placeholder={t('frontend.widget.compare-ads.message-placeholder')}
                maxLength={MAX_MESSAGE_LENGTH}
                showSymbolsCount
            />
        </Container>
    );
};
